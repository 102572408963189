import './Home.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useState } from 'react';
import { Link } from "react-scroll";

import logo from './images/logo.png';
import heroImage from './images/hero.svg';
import aboutImage from './images/2.svg';
import ourOfferImage from './images/3.svg';



function App() {
  AOS.init({
    once: true
  });

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);

  function sendEmail() {
    let emailTest = new RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);

    if (emailTest.test(email) && name !== '' && message !== '') {
      setEmailSent(false);
      setEmailFailed(false);
      fetch('https://email.webnd.co.uk', {
        body: JSON.stringify({
          name,
          email,
          message
        }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST'
      })
        .then((response) => {
          if (response.status === 200) {
            setEmailSent(true);
          } else {
            throw new Error('An error occurred!');
          }
        })
        .catch((err) => {
          setEmailFailed(true);
        })
    } else {
      setEmailFailed(true)
    }
  }

  return (
    <>
      <header className='header'>
        <div className='container header-container'>
          <img className='logo' src={logo} alt='logo' />
          <ul className='nav'>
            <li><Link to='hero' smooth={true}>Home</Link></li>
            <li><Link to='about' smooth={true}>About</Link></li>
            <li><Link to='offer' smooth={true}>Services</Link></li>
            <li><Link to='work' smooth={true}>Work</Link></li>
            <li className='hero-cta'><Link to='contact' smooth={true}>Contact Us</Link></li>
          </ul>
        </div>
      </header>
      <main>
        <section className='hero'>
          <div data-aos="fade-up" data-aos-duration="1100" className='container hero-container'>
            <div className='hero-text'>
              <h1 className='hero-title'>Web Development Services</h1>
              <p className='hero-subtitle'>Are you looking to grow your business with web development?</p>
              <button className='hero-cta'>Get in touch</button>
            </div>
            <img className='hero-img' src={heroImage} alt="hero" />
          </div>
        </section>
        <section className='about'>
          <div className='container about-container'>
            <h2 data-aos="fade-right" data-aos-duration="1000" className='section-title about-title'>Why WebND Solutions?</h2>
            <div className='about-paragraph-container'>
              <p className='about-paragraph' data-aos="fade-right" data-aos-duration="1000">
                At WebND Solutions, we are dedicated to delivering high-quality web development services to businesses. We understand the importance of having a strong online presence in today's world of digital technology, and we are here to help you achieve that goal.<br /><br />
                We bring technical expertise and a creative vision to every project we work on. Our team is ready to create custom solutions that meet your specific needs and exceed your expectations.<br /><br />
                We are constantly exploring new ways to improve our processes and deliver better results for our clients. We take the time to understand your goals, your target audience, and your business needs, and we work closely with you throughout the development process to ensure that you are completely satisfied with the end result.<br /><br />
                Whether you need a simple website, a complex web application, or anything in between, WebND Solutions has the expertise and experience to help you succeed. Contact us today to learn more about how we can help take your online presence to the next level.</p>

              <img className='aboutImage' src={aboutImage} alt="About" data-aos="fade-left" data-aos-duration="1000" />
            </div>
          </div>
        </section>
        <section className='offer'>
          <div className='container offer-container'>
            <h2 className='section-title offer-title' data-aos="fade-right" data-aos-duration="1000">Services</h2>
            <div className='offer-paragraph-container'>
              <div data-aos="fade-right" data-aos-duration="1000" className='offerImageContainer'>
                <img className='offerImage' src={ourOfferImage} alt="Offer" data-aos="fade-right" data-aos-duration="1000" />

                <Link to='work' smooth={true} activeClass="active"><button className='services-cta work-cta'>Our work</button></Link>
                <Link to='contact' smooth={true} activeClass="active"><button className='services-cta blue-cta'>Get in touch</button></Link>
              </div>
              <div className='offer-text' data-aos="fade-left" data-aos-duration="1000">
                <p className='offer-paragraph'>We offer two main types of development. These are:</p>
                <h3 className='offer-sub'>Custom Website Development</h3>
                <p className='offer-paragraph' style={{ marginBottom: '2rem' }}>We work with you to build a high-quality website based on your specification.
                  Our team of developers and designers work together to build the functionality that you require coupled with an easy-to-use interface that engages your audience.
                  Whether you need a simple and sleek website to promote your coffee brand, a web app built for millions of users to interact with, or anything inbetween, we will help you achieve your goals.</p>

                <h3 className='offer-sub'>Website Enhancement</h3>
                <p className='offer-paragraph'>Are you looking to modernize your existing web application? We are perfectly positioned to help you update, improve or further develop your existing web application.
                  Our team can take an old codebase and modernize it to suit modern-day needs.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='work'>
          <div className='container'>
            <h2 className='section-title' data-aos="fade-right" data-aos-duration="1000">Work</h2>
            <p className='work-paragraph' data-aos="fade-right" data-aos-duration="1000">Here you can view some of our previous work so you know what you can expect from us!</p>
            <div className='portfolio-items' data-aos="fade-up" data-aos-duration="1000">
              <div className='portfolio-item'>
                <img src={'https://via.placeholder.com/1600x900'} alt='example1' className='portfolio-image-small' />
                <a target={'_blank'} href='/portfolio/example1' className='view-full-button'>View full page</a>
              </div>
              <div className='portfolio-item'>
                <img src={'https://via.placeholder.com/1600x900'} alt='example2' className='portfolio-image-small' />
                <a target={'_blank'} href='/portfolio/example2' className='view-full-button'>View full page</a>
              </div>
            </div>

          </div>
        </section>
        <section className='contact'>
          <div className='container contact-container' data-aos="fade-in" data-aos-duration="1000">
            <h2 className='section-title contact-title'>Looking to connect?</h2>
            <p className='contact-text'>Simply fill out the form below or send an email to <a href="mailto:hello@webnd.co.uk">hello@webnd.co.uk</a></p>
            <div>
              <div className='input-small-container' >
                <input className='input-small' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
                <input className='input-small' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <textarea placeholder='Message' rows={5} className='input-large' value={message} onChange={(e) => setMessage(e.target.value)} />
              <p className='legal-contact-text'>By submitting this contact form, you agree to receive a reply email from us. We value your privacy and will never share your information with third parties without your permission.</p>
              <button className='contact-send' onClick={() => sendEmail()}>Send</button>
              {emailSent ? <p className='message-confirmation'>Thanks for your email! We will be in contact soon.</p> : null}
              {emailFailed ? <p className='message-failed'>An error occurred!<br />Please ensure your email is in the correct format and all fields are completed.</p> : null}
            </div>
          </div>
        </section>
      </main>
      <footer>
        <div className='container footer-container'>
          <p className='footer-text'>Copyright © 2023 WebND Solutions</p>
        </div>
      </footer>
    </>
  );
}

export default App;
